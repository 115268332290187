<template>
    <div>    
        <HousingNav/>

        <b-overlay :show="isLoadingData || isUpdatingSubCondo">
            <b-card >
                <div class="head-subCondo">
                    <h1>Actualizar subcondominio</h1>
                    <b-button
                        :to="{ name: 'sub-condominiums'}"               
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"                                  
                    >
                        <feather-icon icon="ArrowLeftIcon" style="color: white" />  
                    </b-button>
                </div>                 

                <ValidationObserver
                    ref="updateGroupType"
                    v-slot="{ invalid }"
                    v-if="subCondoData"
                >
                    <b-form @submit.prevent="update">
                    <b-row>
                        <b-col md="3">
                    
                        <ValidationProvider name="condominios" rules="required">
                            <b-form-group label="Condominios" slot-scope="{ valid, errors }">
                            <b-form-select
                                :state="errors[0] ? false : valid ? true : null"
                                v-model="subCondoData.condos"
                            >
                                <option value="">Seleccione Condominio</option>
                                <option
                                v-for="condosList in condos"
                                :key="condosList.id"
                                :value="condosList.id"
                                >
                                {{ condosList.name }}
                                </option>
                            </b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col md="3">
                        <ValidationProvider rules="required" name="sub condo">
                            <b-form-group label="Sub condo" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="subCondoData.name"
                                :state="errors[0] ? false : valid ? true : null"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>

                            <b-col md="3">
                        <ValidationProvider rules="required" name="porcentaje">
                            <b-form-group label="Porcentaje" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="subCondoData.percentage"
                                :state="errors[0] ? false : valid ? true : null"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                        <div class="float-right">
                            <b-button
                            type="submit"
                            class="btn-block"
                            variant="primary"
                            :disabled="invalid || isUpdatingSubCondo"
                            >
                            <b-spinner small v-if="isUpdatingSubCondo" /> Actualizar
                            </b-button>
                        </div>
                        </b-col>
                    </b-row>
                    </b-form>
                </ValidationObserver>
                                

            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import Ripple from 'vue-ripple-directive'    
import HousingNav from "@/modules/fivesClub/components/catalogs/housing/HousingNav"
import { showAlertMessage } from "@/helpers/helpers";


export default {
    directives: {Ripple, },
    components: {
        HousingNav                        
    },
    async created() {
        await this.init()
    },       
    data() {
        return {
            subCondoData: null,
            isLoadingData: false,     
            isUpdatingSubCondo: false,          
        }
    },
    computed: {                                              
        ...mapState('fivesClubCatalogs', ['condos']),
        ...mapState('auth', ['user']),                       
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ['fetchSubCondoById','updateSubCondo']),            
        ...mapMutations('fivesClubCatalogs',['setCondos','setSubCondos','setHousing','setRentalPool']),                   
        async init(){
            this.isLoadingData = true
            const idSubCondo = this.$route.params.id
            if(this.isANumber(idSubCondo)){
                const subCondo = await this.fetchSubCondoById({idSubCondo})                    
                this.subCondoData = subCondo[0]                    
            } 
            this.isLoadingData = false
        } ,                       
        isANumber(string){
            return string ? !isNaN( string ) && string > 0 : false
        }, 
        setHousingRooms(){

        },
        async update(){
            this.isUpdatingSubCondo = true;
            const payload = {
                idUser: this.user.idUser,
                id: this.subCondoData.id,
                name: this.subCondoData.name,
                condos:this.subCondoData.condos,
                percentage:this.subCondoData.percentage,
            }      
            const { status, message } = await this.updateSubCondo(payload)
            if (status) {
                showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )                                                        
            }
            this.isUpdatingSubCondo = false
        },                    
    },
};
</script>
<style scoped>
.head-subCondo{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head-subCondo h1{
    font-size: 1.5rem;
    color: #000;
}
</style>